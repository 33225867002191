<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="marketName">Name</label>
          <vs-input
            class="w-full"
            v-model="market.marketName"
            v-validate="'required'"
            name="marketName"
          />
          <span class="text-danger text-sm" v-show="errors.has('marketName')">{{
            errors.first("marketName")
          }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="fullAddress">Address</label>
          <!-- <vs-input
          class="w-full mt-4"
          placeholder="Enter your address"
          v-model="market.displayAddress"
          v-validate="'required'"
          name="displayAddress"
        />-->

          <gmap-autocomplete
            id="autocomplete"
            class="w-full"
            style="
              padding: 0.7rem !important;
              border: 1px solid rgba(0, 0, 0, 0.2) !important;
              border-radius: 5px;
            "
            placeholder="Enter your address"
            name="fullAddress"
            @place_changed="setPlace"
          ></gmap-autocomplete>
          <span v-if="addressErrorMessage" class="text-danger text-sm">{{
            addressErrorMessage
          }}</span>

          <!-- <button @click="usePlace">Add</button> -->
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="startDate">Start</label>
          <flat-pickr
            v-model="market.startDate"
            :config="{ maxDate: new Date(market.endDate) }"
            class="w-full"
            v-validate="'required'"
            name="startDate"
          />
          <span class="text-danger text-sm" v-show="errors.has('startDate')">{{
            errors.first("startDate")
          }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="endDate">End</label>
          <flat-pickr
            v-model="market.endDate"
            :config="{ minDate: new Date(market.startDate) }"
            class="w-full"
            v-validate="'required'"
            name="endDate"
          />
          <span class="text-danger text-sm" v-show="errors.has('endDate')">{{
            errors.first("endDate")
          }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <vs-row vs-justify="flex-end">
          <vs-button color="success" @click="updateMarketDetail"
            >Update</vs-button
          >
          <vs-button color="danger" style="margin-left: 5px" @click="back"
            >Cancel</vs-button
          >
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
  import { mapActions } from "vuex";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import moment from "moment";
  
  export default {
    name: "EditMarketDetails",
    components: {
      flatPickr,
    },
    data() {
      return {
        autocompleteOptions: {
          componentRestrictions: {
            country: [
            'AU',
          ],
          },
        },
        addressErrorMessage: null,
        market: {
          location: {},
          displayAddress: ''
        },
      };
    },

    methods: {
      ...mapActions("market", ["fetchMarket", "updateMarketDetails"]),
      moment(date) {
        return moment(date);
      },
      getMarketDetail(id) {
        let self = this;
        this.fetchMarket(id).then((res) => {
          this.market = res.data.data;
          document.getElementById(
            "autocomplete"
          ).value = this.market.displayAddress;

        });
      },
      setPlace(place) {
         this.addressErrorMessage = ""
          this.market.fullAddress = place;
          if(this.market.fullAddress) {
            this.market.location.coordinates = [
              this.market.fullAddress.geometry.location.lng(),
              this.market.fullAddress.geometry.location.lat(),
              ];
          }
          this.market.fullAddress = ""
          this.market.displayAddress = document.getElementById("autocomplete").value;


      },

      updateMarketDetail() {
        //   console.log(document.getElementById("autocomplete").value);
        let isFormValid = true;
        if (!document.getElementById("autocomplete").value) {
          isFormValid = false;
          this.addressErrorMessage = "The address field is required";
        }
        this.$validator.validateAll().then((result) => {
          if (result && isFormValid) {
            this.updateMarketDetails(this.market).then((res) => {
              this.market = res.data.data;
              document.getElementById(
                "autocomplete"
              ).value = this.market.displayAddress;
              this.$vs.notify({
                title: "Market Updated",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            });
          }
        });
      },
      back() {
        this.$router.push(`/admin/market`);
      },
    },

    created() {
      let marketId = this.$route.params.id;
      this.getMarketDetail(marketId);
    },
  };
</script>
<style>
  .ql-editor {
    min-height: 200px;
  }
</style>
